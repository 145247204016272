<template>
  <div>
    <!-- 城区集体立管验收导入 -->
    <div class="content1">
      <div class="all">
        <div ref="SearchBar" class="SearchBar" style="padding-left: 20px">
           <el-form  >
          工程编号/名称:
          <el-input style="width: 150px" placeholder="请输入" size="small"  v-model="searchBox.searchText"></el-input>
         <span style="padding-left:20px;">安装施工队:</span>
          <el-input style="width: 150px" placeholder="请输入" size="small"  v-model="searchBox.install"></el-input>
          <span style="padding-left:20px;">现场管理员:</span>
          <el-input style="width: 150px" placeholder="请输入" size="small"  v-model="searchBox.siteManager"></el-input>
         <span style="padding-left:20px;"> 操作人:</span>
          <el-input style="width: 150px" placeholder="请输入" size="small"  v-model="searchBox.userName"></el-input>
           <span style="padding-left:20px;">操作时间:</span>
            <el-date-picker
                size="small"
                v-model="dataTime"
                type="daterange"
                style="width: 220px"
                value-format="yyyy-MM-dd"
                placeholder="选择日期时间">
            </el-date-picker>
            <el-button type="primary" class="SearchBtn" size="small" @click="search">查询</el-button>
            <el-button type="primary" class="SearchBtn" size="small" @click="resetData">重置</el-button>
           </el-form> 
        </div>
        <div class="table" >
          <div style="display:flex;">
            <el-button  class="SearchBtn" size="small" @click="downloadempty">导出空白模板</el-button>
            <el-button  class="SearchBtn" size="small" @click="deriveExcel">导入</el-button>
          </div>
          <!--数据表格-->
          <el-table
              ref="tableData"
              :data="tableData"
              style="width: 100%;margin: 10px 0;"
              border
               height="calc(100vh - 384px)"
              :stripe="true"
              :default-sort = "{prop: 'createTime', order: 'descending'}">
            <el-table-column type="index" label="序号" width="70" align="center"  :index="((searchBox.current-1)*searchBox.size)+1"> </el-table-column>
            <el-table-column prop="projectName" label="工程名称" align="center" show-overflow-tooltip></el-table-column>
            <el-table-column prop="siteManager" label="现场管理员" align="center" show-overflow-tooltip></el-table-column>
            <el-table-column prop="install" label="安装施工队" align="center" show-overflow-tooltip></el-table-column>
            <el-table-column prop="createBy" label="操作人" align="center" show-overflow-tooltip></el-table-column>
            <el-table-column prop="createTime" label="操作时间" align="center" show-overflow-tooltip></el-table-column>
          
          
            <el-table-column label="操作" align="left" show-overflow-tooltip>
              <template slot-scope="{row}">
                <el-button type="primary"  size="small" @click="seeDetil(row)">查看</el-button>
              </template>
            </el-table-column>
          </el-table>
          <div class="pageBox">
            <el-pagination
                :current-page="searchBox.current"
                :background="true"
                :page-sizes="[30, 50, 100]"
                :page-size="searchBox.size"
                layout="total, prev, pager, next, sizes, jumper"
                :total="total"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
            >
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
    <el-dialog
        title="历次验收信息"
        :visible.sync="detilDialog"
        width="65%"
        :close-on-click-modal="false"
        @close="clearAll"
    >
    <el-form ref="project" :model="project"  size="mini" >
      <p class="detil-code">验收工程</p>
           <el-row>
            <el-col :span="24">
              <el-form-item label="工程名称：" prop="positionCode" >
           {{project.projectName}}
          </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item
                label="验收图片："
                prop="positionCode"
              >
                <el-image
                  style="width: 100px; height: 100px"
                  :src="project.urlList[0]"
                  :preview-src-list="project.urlList"
                >
                </el-image>
                <span style="margin-left: 10px"
                  >共{{ project.urlList.length }}张</span
                >
              </el-form-item>
            </el-col>
          </el-row>
          <p class="detil-code">导入明细</p>
          <!--数据表格-->
          <el-table
              ref="tableData"
              :data="tableDataDesc"
              style="width: 100%;margin: 15px 0;"
              border
               
              :stripe="true"
              :default-sort = "{prop: 'createTime', order: 'descending'}">
            <el-table-column prop="contractCode" label="验收结果" align="center" show-overflow-tooltip>   <template slot-scope="{ row }">{{row.result==1?'合格':row.result==2?'不合格':'未验收'}}</template></el-table-column>
            <el-table-column prop="acceptUser" label="验收人" align="center" show-overflow-tooltip></el-table-column>
            <el-table-column prop="acceptDate" label="验收日期" align="center" show-overflow-tooltip></el-table-column>
            <el-table-column prop="address" label="地址" align="center" show-overflow-tooltip></el-table-column>
            <el-table-column prop="standPipe" label="上游立管" align="center" show-overflow-tooltip></el-table-column>
          </el-table>
          <div class="pageBox">
            <el-pagination
                :current-page="searchBoxDesc.current"
                :background="true"
                :page-sizes="[30, 50, 100]"
                :page-size="searchBoxDesc.size"
                layout="total, prev, pager, next, sizes, jumper"
                :total="totalDesc"
                @size-change="handleSizeChangeDesc"
                @current-change="handleCurrentChangeDesc"
            >
            </el-pagination>
          </div>
    </el-form>
    </el-dialog>
    <el-dialog
        title="导入"
        :visible.sync="deriveDialog"
        width="25%"
        @close='clearAll'
        :close-on-click-modal="false"
    >
    <el-form ref="project" :model="project" :rules="rule" size="mini" >
           <el-row>
            <el-col :span="24">
              <el-form-item label="工程名称：" prop="workId" >
           <el-select v-model="project.workId" clearable filterable  placeholder="请选择" size="small" >
               <el-option
                  v-for="item in projectList"
                  :key="item.id"
                  :label="item.projectName"
                  :value="item.id"
                >
            </el-option>
          </el-select>
          </el-form-item>
            </el-col>
          </el-row>
         
          <el-row>
            <el-col :span="24">
              <el-form-item label="选择excel文件："  >
                <span slot="label">
                   
                  选择excel文件：
                   <span style="color:red;margin-left:4px">*</span>
                  </span>
                <el-upload
                ref="uploadE"
                class="upload-demo"
                action="#"
                :auto-upload="true"
                :http-request="repairBeforUploadFun1"
                :on-preview="handlePreviewExcel"
                :on-remove="handleRemoveExcel"
                :before-remove="beforeRemoveExcel"
                multiple
                :limit="1"
                accept=".xls,.xlsx"
                :on-success='handleSuccessExcel'
                :on-exceed="handleExceedExcel"
                :file-list="fileListExcel" >
                <el-button size="small" type="primary">点击上传</el-button>
                <div slot="tip" class="el-upload__tip" v-if="uploadETrue" style="color:red">请上传文件</div>
              </el-upload>
            </el-form-item>
            </el-col>
          </el-row>
          <el-row>

            <el-col :span="24">
              <el-form-item label="验收图片：" >
                <span slot="label">
                   
                  验收图片：
                   <span style="color:red;margin-left:4px">*</span>
                  </span>
                <div>
                <el-upload
                ref="upload"
                action="#"
                :auto-upload="true"
                :http-request="repairBeforUploadFun"
                list-type="picture-card"
                :on-preview="handlePictureCardPreview"
                :on-remove="handleRemove"
                :before-upload="beforUploadImg"
                :on-change="beforHFhandleChangeImg"
                :file-list="repairBeforFilefileList"
                 accept=".jpg,.jpeg,.png,.gif,.bmp,.JPG,.JPEG,.PBG,.GIF,.BMP"
                 :limit="20"
                 :on-exceed="msgLimit"
                >
                <div style="height:20px">
                <img src="../../assets/image/upload.png"/>
                </div>
                <div>
                <span>上传图片</span>
                </div>
                <div slot="tip" class="el-upload__tip" v-if="uploadEImg" style="color:red">请上传图片</div>
            </el-upload>
            </div>
          </el-form-item>
            </el-col>
          </el-row>
          <el-button type="primary" size="small" @click="intoCity()">确认导入</el-button>
    </el-form>
    
    </el-dialog>
      <el-dialog
      title="图片预览"
      :visible.sync="dialogVisibleImg"
      width="100%"
      :close-on-click-modal="false"
    >
    <div><img :src="dialogImageUrl" width="100%"/></div>
    </el-dialog>
  </div>
</template>


<script>
import  {indoor_getFileRecord,indoor_getRecordDetails,prepare_getWorkProject,indoor_intoStandPipe} from '../../RequestPort/intranet/index'
import getNowFormatDate from "./../../common/js/nowDate.js";
import {exportMethod} from "./../../common/js/exportExcel.js";
import {uploadImg} from "../../RequestPort/apply/apply.js"
export default {
  name: "lockout",
  data(){
    return{
      status:'12',
      activeName:'1',
      project:{
        urlList:[],
        checkList:[]
      },
      searchBox:{
        current:1,
        size: 100,
        fileSource:1
      },
      searchBoxDesc:{
        current:1,
        size: 100,
        flag:1
      },
      total:0,
      detilDialog:false,
      deriveDialog:false,
      typeList:[
          {
              label:'合格',
              value:0
          },
          {
              label:'不合格',
              value:1
          },
          {
              label:'未出结果',
              value:2
          }
      ],
      tableData:[{}],
      fileListExcel:[],
      dataTime:[],
      projectList:[],
      repairBeforFileFormData: new FormData(),
      repairBeforFileFormData1:new FormData(),
      repairBeforFilefileList:[],
      dialogVisibleImg:false,
      dialogImageUrl:'',
      form:{designImageUrlList:[]},
      totalDesc:0,
      tableDataDesc:[],
      filedata:{},
      rule:{
         workId:{ required: true, message: "请选择工程名称", trigger: "change" }
      },
      uploadETrue:false,
      uploadEImg:false,
    }
  },
  mounted() {
    this.getOption()
    this.loadList(this.searchBox)
  },
  methods:{
    getOption(){
      prepare_getWorkProject({flag:2}).then(res=>{
        this.projectList = res.data;
      })
    },
    // 打开导入
    deriveExcel(){
      if(this.$refs.project){
        this.$refs.project.resetFields();
      }
      this.deriveDialog = !this.deriveDialog
    },
    handleSuccessExcel(file){
        console.log(file)
      },
    handleRemovePhone(file, fileList) {
        console.log(file, fileList);
      },
      handlePictureCardPreviewPhone(file) {
        this.dialogImageUrl = file.url;
        this.dialogVisible = true;
      },
      // 删除文件
    handleRemoveExcel(file, fileList) {
        this.repairBeforFileFormData1 = new FormData()
        this.fileListExcel = []
      },
      handlePreviewExcel(file) {
        console.log(file);
      },
      handleExceedExcel(files, fileList) {
        this.$message.warning(`当前限制选择 1 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`);
      },
      beforeRemoveExcel(file, fileList) {
        return this.$confirm(`确定移除 ${ file.name }？`,{closeOnClickModal:false});
      },
    seeDetil(row){
      this.searchBoxDesc.recordId = row.id;
      this.searchBoxDesc.workId = row.workId
    
      this.getList()
      this.detilDialog = true;
    },
    getList(page){
      if(page){
        this.searchBoxDesc.current = 1
      }
      indoor_getRecordDetails(this.searchBoxDesc).then(res=>{
        this.project = res.data;
        this.totalDesc = res.data.details.total;
        this.tableDataDesc = res.data.details.records;
        if(res.data.img){
            this.project.urlList = res.data.img.split(',');
        }else{
          this.project.urlList =[];
        }
      })
    },
     handleSizeChangeDesc(val) {
      this.searchBoxDesc.current = 1;
      this.searchBoxDesc.size = val;
      this.getList(1);
    },
    handleCurrentChangeDesc(){
      this.searchBoxDesc.current = val;
      this.getList();
    },
    handleSizeChange(val){
      this.searchBox.current = 1
      this.searchBox.size = val
      this.loadList(this.searchBox)
    },
    handleCurrentChange(val){
      this.searchBox.current = val
      this.loadList()
    },
    search(){
      this.searchBox.current = 1
      this.loadList(this.searchBox)
    },
    loadList(obj){
        if(obj){
          this.searchBox.current = 1
        }
        this.searchBox.startDate =''
        this.searchBox.endDate =''
        if(this.dataTime){
          this.searchBox.startDate = this.dataTime[0]
          this.searchBox.endDate = this.dataTime[1]
        }
        indoor_getFileRecord(this.searchBox).then(res=>{
          this.tableData = res.data.records;
          this.total = res.data.total;
        })
    },
    resetData() {
      this.searchBox={
        current:1,
        size: 100,
        result:[],
        fileSource:1
      }
      this.dataTime =[]
    },
     clearAll(){
      this.project={
        workId:'',
        flag:'',
        urlList:[],
        checkList:[]
      }
      this.objData = {},
      this.repairBeforFileFormData1 = new FormData();
      this.repairBeforFileFormData = new FormData();
      this.form.designImageUrlList =[]
      this.fileListExcel =[];
      this.repairBeforFilefileList = []
      this.loadList(this.searchBox)
    },
    downloadempty(){
       const elink = document.createElement('a')

      elink.href = 'https://gas-dev.oss-cn-beijing.aliyuncs.com/ZYMenuIcon/cityPipe1.xls'
      const fileName = getNowFormatDate()+".xls";

      elink.setAttribute('download', fileName)

      elink.style.display = 'none'

      document.body.appendChild(elink)

        setTimeout(() => {
          elink.click()

          document.body.removeChild(elink)

        }, 66)
  },
  repairBeforUploadFun1(e){
    this.positionCode = '1'
    // this.repairBeforFileFormData1 = new FormData()
    // this.repairBeforFileFormData1.append('file','value')
    this.repairBeforFileFormData1.set('file',e.file)
    this.fileListExcel.push(e.file)
    this.uploadETrue = false
    // console.log(this.repairBeforFileFormData1.get('file'))
  },
  async repairBeforUploadFun(e){

      this.repairBeforFileFormData.append('file',e.file)
   await   uploadImg(this.repairBeforFileFormData).then((res)=> {
    this.uploadEImg = false
         this.form.designImageUrlList.push(res.data.url)
        this.repairBeforFileFormData.delete('file')
      })
      },
      beforUploadImg(file){
      const fileSuffix = file.name.substring(file.name.lastIndexOf(".") + 1);

      const whiteList = ['jpg','jpeg','png','gif','bmp','JPG','JPEG','PBG','GIF','BMP'];
      if (whiteList.indexOf(fileSuffix) === -1) {
        this.$message.error("上传文件只能是 jpg、jpeg、png、gif、bmp");
        return false;
      }
      const size = file.size / 1024 / 1024
      if (size > 100) {
        this.$message.error("大小必须小于100M");
        return false;
      }
    },
    beforHFhandleChangeImg(file, fileList) {
      this.repairBeforFilefileList = fileList
    },
    handleRemove(file, fileList) {
    let index = this.getArrayIndex(this.repairBeforFilefileList,file);
    this.form.designImageUrlList.splice(index,1)
    },
    getArrayIndex(arr, obj) {
      var i = arr.length;
      while (i--) {
        if (arr[i] === obj) {
          return i;
        }
      }
      return -1;
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisibleImg = true;
    },
    msgLimit(file,fileList){
      this.$message.error("最多上传20张图片");
    },
    intoCity(){
      console.log(this.fileListExcel)
      if(this.fileListExcel.length == 0){
        this.uploadETrue = true
        return
      } else {
        this.uploadETrue = false
      }
      if(this.repairBeforFilefileList.length == 0){
        this.uploadEImg = true
        return
      } else {
        this.uploadEImg = false
      }
      this.$refs["project"].validate((valid) => {
        if (valid) {
          this.repairBeforFileFormData1.set('img',this.form.designImageUrlList.join(','))
          this.repairBeforFileFormData1.set('workId',this.project.workId)
          indoor_intoStandPipe(this.repairBeforFileFormData1).then(res=>{
              this.$message.success('上传成功');
              this.clearAll();
              this.loadList(1);
              this.deriveDialog = false
          })
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
::v-deep .el-upload--picture-card{width:100px;height:100px;line-height:100px}
::v-deep .el-upload-list--picture-card .el-upload-list__item{width:100px;height:100px;line-height:100px}
.detil-code{
  padding-left: 10px;
  font-size: 16px;
  height: 26px;
  line-height: 16px;
  color: #000000;
  position: relative;
}
.detil-code:before{
  content: '';
  position: absolute;
  width: 3px;
  height: 16px;
  background-color: #007AFF;
  top: 0;
  left: 0;
}
.content {
  background: #f3f4f8;
  width: 100%;
  border-radius: 6px;
  padding: 10px;
}
.SearchBar {
  /* border: 1px solid #ecedf1; */
  border-radius: 5px;
  margin: 10px;
  padding-top: 1%;
  padding-bottom: 1%;
  background-color: #ffffff;
}
.SearchBtn{
  margin-left: 10px;
}
.table {
  padding: 10px;
  border-radius: 5px;
  margin: 10px;
  background-color: #ffffff;
}
</style>